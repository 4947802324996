import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Layout from "../Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../Redux/Actions/UserActions";
import { computeHeadingLevel } from "@testing-library/react";
import moment from "moment";

export default function BlogList() {
  const optionsslider = {
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    stagePadding: 10,
    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogdata.blogList);
  console.log(blogs?.data);
  const [carouselKey, setCarouselKey] = useState(0);

  useEffect(() => {
    dispatch(getBlogs());
    
  }, []);

  useEffect(() => {
    setCarouselKey((prevKey) => prevKey + 1);
  }, [dispatch,blogs]);

  console.log("first")

  return (
    <Layout>
      <section className="page-mt">
        <Container>
          <div className="blog-heading-main">
            <h2>Pharma News</h2>
          </div>

          <OwlCarousel
            key={carouselKey}
            className="owl-theme blog-list-carousel"
            loop
            margin={10}
            nav={false}
            dots={true}
            {...optionsslider}
          >
            {blogs &&
              blogs?.data?.length > 0 &&
              blogs?.data?.map((blogs) => {
                console.log(blogs);
                return (
                  <div className="item">
                    <Row>
                      <Col lg={6}>
                        <div className="blog-list-slide">
                          <img
                            src={`https://api.telepharmacare.com/public/production/${blogs?.blog_image}`}
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="blog-list-slide-text">
                          <p>
                            {" "}
                            {moment(blogs?.createdAt).format("MMMM Do, YYYY")}
                          </p>
                          <h2>{blogs?.title}</h2>
                          <Link to={`/blog-view/${blogs?._id}`}>Read more</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}

            {/* <div className="item">
              <Row>
                <Col lg={6}>
                  <div className="blog-list-slide">
                    <img
                      src="https://i.imghippo.com/files/JDYuo1715061628.png"
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="blog-list-slide-text">
                    <p> Feb 01, 2024</p>
                    <h2>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h2>
                    <Link to="/blog-view">Read more</Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="item">
              <Row>
                <Col lg={6}>
                  <div className="blog-list-slide">
                    <img
                      src="https://i.imghippo.com/files/JDYuo1715061628.png"
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="blog-list-slide-text">
                    <p> Feb 01, 2024</p>
                    <h2>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h2>
                    <Link to="/blog-view">Read more</Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="item">
              <Row>
                <Col lg={6}>
                  <div className="blog-list-slide">
                    <img
                      src="https://i.imghippo.com/files/JDYuo1715061628.png"
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="blog-list-slide-text">
                    <p> Feb 01, 2024</p>
                    <h2>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h2>
                    <Link to="/blog-view">Read more</Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="item">
              <Row>
                <Col lg={6}>
                  <div className="blog-list-slide">
                    <img
                      src="https://i.imghippo.com/files/JDYuo1715061628.png"
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="blog-list-slide-text">
                    <p> Feb 01, 2024</p>
                    <h2>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h2>
                    <Link to="/blog-view">Read more</Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="item">
              <Row>
                <Col lg={6}>
                  <div className="blog-list-slide">
                    <img
                      src="https://i.imghippo.com/files/JDYuo1715061628.png"
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="blog-list-slide-text">
                    <p> Feb 01, 2024</p>
                    <h2>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h2>
                    <Link to="/blog-view">Read more</Link>
                  </div>
                </Col>
              </Row>
            </div> */}
          </OwlCarousel>
          {/* <div className="owl-carousel owl-theme ">
              
            </div> */}

          <Row className="mt-5">

          {blogs?.data?.map((blog)=>{
            return (
              <Col lg={6} className="mb-5">
              <div className="blog-list-card">
                <img
                  src={`https://api.telepharmacare.com/public/production/${blog?.blog_image}`}
                  alt=""
                />
                <div className="blog-list-card-text">
                  <p>{moment(blog?.createdAt).format("MMMM Do, YYYY")}</p>
                  <h2>
                  {blog?.title}
                  </h2>
                  <Link to={`/blog-view/${blog?._id}`}>Read more</Link>
                </div>
              </div>
            </Col>

            )
          })}
            
           
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
