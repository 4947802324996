import { createSlice } from "@reduxjs/toolkit";
import { findblogbyId, getBlogs } from "../Actions/UserActions";

export const blogSlice = createSlice({
  name: "blogSlice",
  initialState: {
    blogList: null,
    blog: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.blogList = action.payload;
      })
      .addCase(findblogbyId.fulfilled, (state, action) => {
        state.blog = action.payload;
      });
  },
});
