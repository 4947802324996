import { configureStore } from "@reduxjs/toolkit";
import { blogSlice } from "./Reducers/BlogSlice";
import { landingPageSlice } from "./Reducers/landingpageSlice";








export const store = configureStore({
  reducer: {
    blogdata:blogSlice.reducer,
    landingpageadata:landingPageSlice.reducer

  },
});