import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import BlogList from "./pages/BlogList";
import BlogView from "./pages/BlogView";
import NotFound from "./Components/NotFound";
import CookiePolicy from "./pages/CookiePolicy";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
            <Route path="blog-list" element={<BlogList />} />
            <Route path="/blog-view/:id" element={<BlogView />} />
            {/* <Route path="/NotFound" element={<NotFound />} /> */}
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
