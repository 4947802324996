import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
export default function TermsConditions() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <section className="page-mt pt-5">
        <Container>
          <div class="container">
            <div class="privacy_text">
              <div class="header-logo text-center">
                <Link class="main-logo link" to="/">
                  <img src={require("../images/logo-update.svg").default} />
                </Link>
              </div>
              <h3>TERMS AND CONDITIONS</h3>
              <p>Effective date: 2023-04-23</p>
              <p>
                PLEASE READ THE TERMS AS HEREAFTER SET FORTH (THIS "AGREEMENT")
                CAREFULLY BEFORE USING  <Link to={"https://telepharmacare.com"}> https://telepharmacare.com</Link> OR ANY OTHER
                RELATED WEBSITES (“WEBSITES”), AND TELEPHARMACARE MOBILE
                APPLICATION TOGETHER WITH ITS VARIOUS FUNCTIONALITIES
                ("APPLICATION") (collectively, the “PLATFORMS”). THE PLATFORMS
                ARE SOFTWARE SOLUTIONS OFFERING CUSTOMERS ACCESS TO A NETWORK OF
                HEALTHCARE AND WELLNESS PROVIDERS AND HEALTHCARE AND WELLNESS
                SERVICES ("SERVICES").
              </p>
              <p>
                BY REGISTERING FOR AN ACCOUNT ON THE APPLICATION ON A MOBILE
                DEVICE ("DEVICE") OR MAKING A PURCHASE VIA THE WEBSITE OR
                THROUGH THE APPLICATION, AND GENERALLY USING THE PLATFORMS, YOU
                AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT and all other
                terms and policies that appear on the PLATFORMS (including any
                TERMS set out in our PRIVACY POLICY at 
                <Link to="https://telepharmacare.com">
                   https://telepharmacare.com
                </Link>
                or on the Application).
              </p>

              <p>
                This Agreement is made between Telepharmacare Global which is a
                subsidiary company that belongs to Mukooza Corporate Group AB
                (556781-9668) ("Telepharmacare", "us", "we" or "our" as the case
                may be) and you (collectively the “Parties”) with respect to
                your use of the Platforms and any functionalities, services or
                features offered via or in connection with the Platforms, and
                references in this Agreement to the use of any of the Platforms
                extend to the use of such functionalities, services or features
                as well. The Services may be provided by Telepharmacare, our
                related companies, Healthcare Providers on Telepharmacare’s
                panel or partners. For the purposes of this Agreement,
                “partners” shall include suppliers or delivery service providers
                of products/medication. IF YOU DO NOT AGREE TO THE TERMS OF THIS
                AGREEMENT OR ARE INELIGIBLE TO USE THE PLATFORMS, PLEASE DO NOT
                USE THE SAME.
              </p>
              <p>
                IMPORTANT: DO NOT USE THE VIDEO/CHAT/AUDIO CONSULTATION
                FUNCTIONALITY ON THE APPLICATION FOR A MEDICAL EMERGENCY. If you
                are experiencing a medical emergency, please consult a doctor in
                person or call the emergency medical services number
                immediately.
              </p>
              <h5>1. YOUR ACCOUNT</h5>
              <p>
                1.1 You will need to have an account in order to access the
                Services via the Platforms. You may sign up for an account on
                the Platform. The registration process requires you to provide
                your personal information, including your name, ID/passport
                number, date of birth, address, telephone number, email address
                and/or such other information as may be required by us.
              </p>
              <p>
                1.2 You shall comply with all security procedures applicable to
                the Platforms which we may introduce from time to time.
              </p>
              <p>
                1.3 You shall, in your sole responsibility, maintain the
                confidentiality of your account information for the Platforms
                (including any user IDs and passwords) and you will be
                responsible for any disclosure or unauthorised use thereof. You
                shall not at any time disclose such account information to any
                other party.
              </p>
              <p>
                1.4 If you believe that the security of your account information
                has been compromised, you shall notify us immediately.
              </p>
              <p>
                1.5 By signing up for an account via the Platforms, you
                represent and warrant that:
              </p>
              <p>1.5.1 you are at least 18 years of age;</p>
              <p>
                1.5.2 you have the full right, power, and legal authority to
                enter into this Agreement;
              </p>
              <p>
                1.5.3 all information, data and particulars that you provide is
                complete, accurate, true and correct; and
              </p>
              <p>
                1.5.4 you will use the Platforms for your personal use only and
                you will not authorise other persons to use your account nor
                transfer or assign it to any other person (with the exception of
                accounts established for children of whom you are the parent or
                legal guardian or geriatrics, as described below).
              </p>
              <p>
                We shall be entitled, in the exercise of our sole discretion, to
                (i) refuse your registration if you fail to fulfil any one of
                the eligibility criteria above; (ii) suspend/terminate your
                account under the Application; and/or (iii) change the above
                eligibility criteria at any time.
              </p>
              <p>
                1.6 If you are the parent/legal guardian of a child/ward that is
                below 18 years of age, you can create a dependent sub-profile
                under your account in order for your child/ward to use the
                Platforms and access the Services. By allowing your child to use
                your Account to access the Services, you agree to take
                responsibility for: (i) your child/ward’s actions in their use
                of the Application; (ii) any charges associated with your
                child/ward’s use of the Application; and (iii) their compliance
                with this Agreement. You must be present and accompany your
                child/ward during any video/chat/audio consultation and be
                available at the point of product delivery for any products
                purchased by your child/ward.
              </p>
              <p>
                1.7 If you are below 18 years of age, please approach your
                parent/guardian to create a dependent sub-profile under your
                parent/guardian’s account so that you can access the Services.
              </p>
              <p>
                1.8 If you are using the Services on behalf of a geriatric with
                special needs, including but not limited to vision and hearing
                difficulties, you must be present during any video/chat/audio or
                physical consultation with the geriatric. Upon purchasing any
                products, you must be available at the point of product
                delivery. You are responsible for payment for the Services
                provided to, and purchase and arrangement of delivery of product
                for the geriatric. You are assuming the obligations of this
                Agreement as they relate to the geriatric.
              </p>
              <h5>2. YOUR USE OF THE PLATFORMS </h5>
              <p>
                2.1 Subject always to your continuing compliance with the terms
                of this Agreement, we agree to grant you a non-transferable,
                non-sublicensable and non-exclusive licence to use the Website
                and/or Application on the Device for the purpose of obtaining
                the Services. All other rights not expressly granted to you are
                reserved by Telepharmacare.
              </p>
              <p>
                2.2 Access to some software components used in the Website
                and/or Application may be offered under third party licences
                that we may notify you of, in which case your use of those
                components is governed by such terms to the extent only of any
                inconsistency between this Agreement and those terms.
              </p>
              <p>
                2.3 Without prejudice to the generality of the foregoing, you
                shall not (and shall not, knowingly or otherwise, authorise,
                allow or assist any third party to):
              </p>
              <p>
                2.3.1 modify or adapt the whole or any part of the Website
                and/or Application, or permit the Website and/or Application or
                any part of it to be combined with, or become incorporated in,
                any other application, programs or other platforms created by
                you;
              </p>
              <p>
                2.3.2 disassemble, decompile, reverse-engineer or otherwise
                attempt to derive the source code of the Website and/or
                Application or any components thereof;
              </p>
              <p>
                2.3.3. communicate, republish, upload, post, transmit, edit,
                re-use, rent, lease, loan, sell, assign, transfer, distribute,
                make available, license, sublicense or create derivative works
                or adaptations based on the whole or any part of the Website
                and/or Application;
              </p>
              <p>
                2.3.4 use the Website and/or Application in any unlawful manner,
                for any unlawful purpose, or in any manner inconsistent with
                this Agreement, or in contravention of any applicable laws,
                including infringement of our intellectual property rights or
                those of any third party in relation to the Application;
              </p>
              <p>
                2.3.5 use the Website and/or Application in a way that could
                damage, disable, impair or compromise the Application (or the
                systems or security of the Website and/or Application or any
                other computer systems or devices used in connection therewith)
                or interfere with other users or affect the reputation of the
                Website and/or Application and/or Telepharmacare;
              </p>
              <p>
                2.3.6 post or transmit any file or email which contains viruses,
                worms, Trojan horses or any other damaging or destructive
                elements;
              </p>
              <p>
                2.3.7 use any automated process or service to access and/or use
                the Website and/or Application; and/or
              </p>
              <p>
                2.3.8 provide, distribute or share, or enable the provision,
                distribution or sharing of, the Website and/or Application (or
                any data associated therewith) with any third party.
              </p>
              <h5>3. SERVICES</h5>
              <p>(I) General</p>
              <p>3.1 It shall be your sole responsibility, at your own cost:</p>
              <p>
                3.1.1 to obtain all necessary hardware, software and
                communications services necessary for your use of the
                Application. Any network connectivity costs shall be born
                exclusively by you;
              </p>
              <p>
                3.1.2 to take steps to ensure the security of your Device; and
              </p>
              <p>
                3.1.3 to make back-ups of data or other content posted via the
                Application, as these may be subsequently deleted by us or our
                service providers at any time without notice to you.
              </p>
              <p>3.2 You agree that:</p>
              <p>
                3.2.1 you are responsible for the correctness and accuracy of
                the information, particulars, statements, photographs,
                laboratory test results or diagnostic images furnished by you to
                Telepharmacare and/or the Healthcare Provider;
              </p>
              <p>
                3.2.2 you shall be personally liable for, and to pay, any fees
                or charges in accordance with any terms in effect at the time
                they are incurred;
              </p>
              <p>
                3.2.3 the licences granted herein do not confer on you any
                rights to use any other intellectual property rights of
                Telepharmacare, our affiliates, partners and/or licensors (as
                the case may be), including "Telepharmacare", the Telepharmacare
                logos and any other logos, service marks, slogans, product names
                and designations and other proprietary indicia used as part of
                the Platforms, all of which are and remain the property of
                Telepharmacare, our affiliates, partners and/or licensors (as
                the case may be);
              </p>
              <p>3.2.4 you shall comply with all applicable laws; and</p>
              <p>
                3.2.5 we shall have the right to at any time and from time to
                time:
              </p>
              <p>
                3.2.6.1 automatically update the Application and its components
                on your Device, add or remove functionalities, features or
                services (collectively, "Application Functions");
              </p>
              <p>
                3.2.6.2 vary user account rights or impose user account
                restrictions, resource limits or fees or suspend or terminate
                Application Functions and/or user rights; and/or
              </p>
              <p>
                3.2.6.3 deny or restrict access to the Application or any
                Application Functions whether to any user or generally, or to
                block access from or to any resources at any time without
                ascribing any reasons whatsoever,and in any such event, you
                agree that no claims shall lie against us, our related
                companies, our agents, our partners or our service providers in
                connection therewith.
              </p>
              <p>(II) Video/Chat/Audio Consultation/Healthcare Services</p>
              <p>
                3.3 Telepharmacare may offer various healthcare services on the
                Platforms including medication consultations, and other health
                and wellness services (“Healthcare Services”).
              </p>
              <p>
                3.4 If you are using the video/chat/audio consultation
                functionality, you acknowledge and agree that:
              </p>
              <p>
                3.4.1 You may arrange and schedule video/chat/audio
                consultations with providers who deliver the Healthcare Services
                (“Healthcare Providers”);
              </p>
              <p>
                3.4.2 All requests for video/chat/audio consultation shall be
                subject to the Healthcare Providers' acceptance in his/her sole
                discretion and each consultation accepted by a Healthcare
                Provider shall constitute a separate agreement between the
                Healthcare Provider and you, to which neither Telepharmacare nor
                our affiliates are a party to;
              </p>
              <p>
                3.4.3 The Healthcare Providers are independent professionals.
                Each Healthcare Provider is responsible for his/her Healthcare
                Services rendered to you and compliance with the requirements of
                his/her profession and licence;
              </p>
              <p>
                3.4.4 Telepharmacare is merely a provider of the Platforms, and
                other supporting services, and does not provide or engage in
                Healthcare Services. Neither Telepharmacare nor any third
                parties who promote the Healthcare Services, facilitate the
                provision of the Healthcare Services or provide you with a link
                to the Healthcare Services shall be liable for any professional
                advice you obtain from a Healthcare Provider via the Website or
                Application. Telepharmacare does not take on any duty of care to
                you;
              </p>
              <p>
                3.4.5 You shall ensure that the internet connection to which
                your Device is connected is of sufficient quality and strength
                to host a video/chat/audio consultation, otherwise
                video/chat/audio quality may suffer;
              </p>
              <p>
                3.4.6 Once your request is accepted, the Application may provide
                your information to the Healthcare Provider, including your name
                and identification number. You agree to provide proof of
                identity (which includes a valid photo identification) and such
                information as requested by the Healthcare Provider to verify
                your identity at the start of or at any time during the
                video/chat/audio consultation. You acknowledge that you may be
                denied Healthcare Services if you refuse to provide proof of
                identity or assume a false identity. The Healthcare Provider
                reserves the right to decline any request for video/chat/audio
                consultation through the Application in its absolute discretion;
              </p>
              <p>
                3.4.7 You acknowledge that there are inherent limitations to
                video/chat/audio consultations and that in respect of certain
                medical situations, physical consultations may be a more
                appropriate medium for medication evaluation or consultation.
                Such medical situations include, but are not limited to: (i)
                situations requiring urgent care or emergency conditions; (ii)
                serious conditions (e.g., breathlessness, severe or persistent
                pains, cardiac arrest); (iii) chronic conditions which have not
                been previously diagnosed by a doctor; and (iv) any condition
                requiring further investigations including but not limited to
                laboratory tests, and imaging modalities;
              </p>
              <p>
                3.4.8 In the case of the video/chat/audio consultation
                functionality, you understand and agree that the medication
                consultation provided shall be intended as a provisional opinion
                (or second opinion) and shall be solely based on information
                made available, presented and discussed for or during the
                video/chat/audio consultation. Such medication consultation is
                not intended to be a substitute for an in-person consultation or
                treatment from your doctor. It is advisable to seek further
                clarification, examination, diagnosis and/or an in-person
                consultation for a more comprehensive medication assessment with
                your doctor before beginning any definitive treatment;
              </p>
              <p>
                3.4.9 Telepharmacare does not, and shall not be deemed to,
                direct or control the Healthcare Provider generally or in the
                Healthcare Provider's performance under this Agreement
                specifically, including in connection with the Healthcare
                Provider's provision of Healthcare Services or the Healthcare
                Provider's acts or omissions;
              </p>
              <p>
                3.4.10 Any opinions, advice or information expressed by the
                Healthcare Provider are those of the Healthcare Provider alone
                and they do not reflect the opinions of Telepharmacare;
              </p>
              <p>
                3.4.11 You are responsible for the correctness and accuracy of
                the particulars, statements and images including your symptoms,
                health history, photographs, laboratory test results or
                diagnostic images furnished/uploaded by you to the Healthcare
                Provider;
              </p>
              <p>
                3.4.12 You shall comply with any advice by any Healthcare
                Provider to consult a doctor or other healthcare professional in
                person or seek emergency or other treatment;
              </p>
              <p>
                3.4.13 You shall (i) comply with any instructions given in
                relation to any prescription provided to you including, in
                particular, route, timing and dosages; (ii) promptly report any
                side effects of any prescription to a doctor; (iii) check the
                labels of the medication prescribed to verify the medication
                matches the prescription before consuming the medication
                delivered; (iv) protect anything prescribed to you and not
                permit any other person access to the prescribed medication; and
                (v) not use any prescription after its expiry date;
              </p>
              <p>
                3.4.14 You shall not record in any media your interactions with
                the Healthcare Providers through the Application;
              </p>
              <p>
                3.4.15 You shall not conduct yourself in an inappropriate manner
                when interacting with the Healthcare Provider, or display/upload
                any inappropriate or medically-irrelevant images to the
                Application. Any such conduct may result in the termination of
                your Account and/or deletion of the images from the Application;
                and
              </p>
              <p>
                3.4.16 You shall comply with Telepharmacare’s cancellation terms
                set out in clause 7 below.
              </p>
              <p>(III) Marketplace on the Platforms</p>
              <p>
                3.5 Telepharmacare’s Marketplace on the Platforms offers various
                healthcare-related products and services for purchase
                (“Healthcare Products”). Healthcare Products on the Marketplace
                may be sold by third party vendors or Telepharmacare
                (“Marketplace Providers”). If you are using the Marketplace
                functionality on any of the Platforms, the following terms shall
                apply:
              </p>
              <p>(a) Placing of Orders</p>
              <p>
                3.5.1 You may place an order by completing the order form on the
                Platforms and clicking "Confirm Purchase" or “Purchase”
                ("Order"). Marketplace Providers will not accept Orders placed
                in any other manner.
              </p>
              <p>
                3.5.2 All Orders shall be subject to Marketplace Providers'
                acceptance in its sole discretion and each Order accepted by
                Marketplace Providers shall constitute a separate agreement
                between Marketplace Provider and you. You acknowledge that
                unless you receive a notice from Marketplace Provider accepting
                your Order or arranging for an appointment, Marketplace
                Providers shall not be party to any legally binding agreement
                with you for the sale of or other dealings with the Healthcare
                Product and accordingly Marketplace Providers shall not be
                liable for any losses which may be incurred as a result. The
                Marketplace Provider reserves the right to decline to process or
                accept any Order received from or through the Platform in its
                absolute discretion.
              </p>
              <p>
                3.5.3 Notwithstanding delivery and the passing of risk in the
                Healthcare Products or any other provision of this Agreement,
                the Healthcare Products shall not pass to you until
                Telepharmacare has received in cleared funds payment in full of
                the price of the Healthcare Products and all other goods agreed
                to be sold by Telepharmacare to you for which payment is then
                due.
              </p>
              <p>3.5.4 You acknowledge that:</p>
              <p>
                While we endeavour to provide an accurate description of the
                Healthcare Products, we do not warrant that such description is
                accurate, current, or free from error;
              </p>
              <p>
                3.5.5 Telepharmacare shall not be liable for any failure or
                delay in delivery of your purchased Healthcare Product howsoever
                caused;
              </p>
              <p>
                3.5.6 The pricing or availability of the Healthcare Products may
                change at any time at Telepharmacare’s discretion.
                Telepharmacare shall not be responsible for any such changes;
                and
              </p>
              <p>
                3.5.7 You shall pay the relevant fees for any Healthcare
                Products purchased, and abide by all applicable payment terms as
                may be made known to you on the Platform or by Telepharmacare.
              </p>
              <h5>4. PAYMENT</h5>
              <p>(I) General</p>
              <p>
                4.1 You agree to pay all Fees to your Account in accordance with
                the Fees, charges, and billing terms in effect at the time the
                Fees are due and payable via credit card.
              </p>
              <p>
                4.2 You authorize us to make any enquiries we consider necessary
                to validate and verify your identity (whether directly or
                through third parties) at any time for any reason whatsoever.
              </p>
              <p>
                4.3 In connection with your use of the Application, you shall
                bear the following fees and charges (collectively, the “Fees”):
              </p>
              <p>
                4.3.1 in respect of video/chat/audio a consultation fee for each
                completed Healthcare Service requested by you through the
                Application ("Consultation Fee") calculated based on the rates
                as prescribed by Telepharmacare on the Application from time to
                time.
              </p>
              <p>4.3.2 in respect of the Marketplace, the Product Price;</p>
              <p>
                4.3.3 in respect of the Subscriptions (if any), the
                subscription/annual fees as stated in the plan description on
                the Application (“Subscription Fees”);
              </p>
              <p>4.3.4 delivery fees (if any); and/or</p>
              <p>4.3.5 taxes (where applicable).</p>
              <p>
                4.3.6 For the avoidance of doubt, Telepharmacare’s determination
                of the Fees shall be final, conclusive and binding on you.
              </p>
              <p>
                4.3.7 All Fees shall be paid in advance, are non-refundable as
                permitted under applicable refund policies as may be notified to
                you, and you further agree to bear all taxes and other duties
                payable thereon.
              </p>
              <p>
                4.3.8 We may use payment processing agents to process your
                payment and of any Fees. When you provide us with your credit
                card details, you authorise us and/or our payment processing
                agents to charge your credit card account automatically upon the
                expiry of each payment period as applicable in relation to the
                Fees. If we cannot charge your card successfully, we have the
                right to immediately terminate the transaction and/or access to
                your account.
              </p>
              <h5>5. CANCELLATIONS, EXCHANGES AND REFUNDS</h5>
              <p>
                5.1 In respect of the Healthcare Services / Medication
                Consultation function: Unless as otherwise specified in the
                listing on the Platforms, you will not be entitled to a refund
                of such fees already paid (i) in the event that you cancel a
                scheduled appointment less than 24 hours in advance or if you
                miss a scheduled appointment or (ii) in respect of any
                consultation that has either been completed or terminated by
                you.
              </p>
              <p>
                5.2 In respect of the purchase of any Healthcare Products via
                the Marketplace on the Platforms: Unless as otherwise specified
                in the listing on the Platforms,
              </p>
              <p>
                5.2.1 such orders successfully made through the Platforms are
                strictly not eligible for cancellation; and
              </p>
              <p>
                5.2.2 Healthcare Products purchased through Marketplace on the
                Platforms are also not eligible for exchange and are
                non-refundable. Any requests made for refunds/exchanges in the
                event that the purchased product is damaged, or the service
                rendered failed to fulfil its description in Marketplace will be
                subject to Telepharmacare’s review on a caseby-case basis. The
                decision as to whether any refund/exchange should be allowed is
                at the sole discretion of Telepharmacare.
              </p>
              <p>
                5.3 In the event you are entitled to any refund for any reason
                in respect of any products or services on the Telepharmacare
                Platforms, you agree that Telepharmacare will in its sole
                discretion issue you with a refund to where the purchase was
                made using a credit card, credit the refund to the same credit
                card. We may require you to provide additional information prior
                to processing any refund and you agree to cooperate with us.
              </p>
              <h5>6. PRIVACY</h5>
              <p>
                6.1 In using the Application or Website or any services provided
                by us, Telepharmacare will need to collect your personal data in
                order to provide you with our Services or Healthcare Products.
                In providing us with your personal data, you consent to our
                collection, use, processing, and disclosure of your personal
                data in accordance with our Privacy Policy found at 
                <Link to="https://telepharmacare.com">
                  https://telepharmacare.com
                </Link>
                or on the Application.
              </p>
              <p>
                6.2 For the avoidance of doubt, our Privacy Policy also applies
                to any data provided to Telepharmacare offline (whether in
                Telepharmacare physical pharmacies, during health
                screenings/vaccinations or otherwise) in order for us to perform
                any services for your benefit.
              </p>
              <p>
                6.3 Telepharmacare is required to comply with local healthcare
                privacy and security laws and maintain safeguards to protect the
                security of your health information. In addition, the
                information you provide to your Healthcare Provider during a
                video/chat/audio consultation is legally confidential, except
                for certain legal exceptions as more fully described in our
                Privacy Policy. Information relating to your care, including
                consultation notes are stored on secure, encrypted servers from
                an external provider.
              </p>
              <h5>7. DISCLAIMER OF WARRANTIES AND LIABILITY</h5>
              <p>
                7.1 To the maximum extent permitted by law, you irrevocably
                agree and acknowledge that:
              </p>
              <p>
                7.1.1 we do not warrant or make any representations that the
                Website and/or Application is the appropriate channel of
                consultation for your particular healthcare problem, or meets
                your specific requirements. Telepharmacare disclaims any
                liability for any use of the Website and/or Application for the
                provision of any emergency services or where diagnosis or
                treatment-in-person is required. You should also consult a
                doctor in person or contact your local emergency services
                immediately if your medical and medication condition and
                consultation is not minor or cannot be diagnosed or treated
                without a physical consultation, or otherwise falls within the
                following scope of conditions including without limitation: (i)
                urgent care or emergency conditions; (ii) serious conditions
                (e.g. breathlessness, severe or persistent pains, cardiac
                arrest); (iii) chronic conditions which have not been previously
                diagnosed by a doctor; (iv) any condition deemed during a
                video/chat/audio consultation via the Application to be in need
                of a physical consultation for further clarification,
                examination, diagnosis and/or treatment; and (v) any condition
                requiring further investigations including but not limited to
                laboratory tests, and imaging modalities;
              </p>
              <p>
                7.1.2 we are not responsible for any of the information made
                available or accessed on or through the Website and/or
                Application or any decisions made by you based on any
                information made available or accessed on or through the Website
                and/or Application;
              </p>
              <p>
                7.1.3 we do not endorse or recommend any specific tests,
                physicians, pharmacists, pharmacy technicians, products,
                procedures, opinions, or other information that may be mentioned
                on the Website and/or Application and that any arrangement
                entered into between you and any Healthcare Provider or any
                third party named or linked to or from the Website and/or
                Application is at your sole risk and responsibility. The
                inclusion of Healthcare Providers on the Website and/or
                Application does not imply recommendation or endorsement of such
                professional nor is such information intended as a tool for
                verifying the credentials, qualifications, or abilities of any
                professional contained therein;
              </p>
              <p>
                7.1.4 the Website and/or Application (together with any
                Services, or other content, material or information available on
                the Website and/or Application) are provided on an "as is" and
                "as available" basis, with all faults and without warranty of
                any kind. Your use of the Website and/or Application is at your
                own risk;
              </p>
              <p>
                7.1.5 the Website and/or Application may use transmissions over
                the Internet which are never completely private or secure. You
                understand that any personal data, message or information which
                you send in the course of the use of the Website and/or
                Application may be made public on the Website and/or
                Application, and read or intercepted by others;
              </p>
              <p>
                7.1.6 Telepharmacare does not warrant and hereby disclaims any
                representation, warranty or term with respect to the Website,
                Application and/or the Application Functions, whether express,
                implied or statutory, including but not limited to:
              </p>
              <p>
                (i) merchantability, satisfactory quality, fitness for a
                particular purpose, title, accuracy, quiet enjoyment, and
                non-infringement of third-party rights, or as to the accuracy,
                correctness, reliability, timeliness, non-infringement of or
                compliance with any laws, regulations and/or third-party rights
                in connection with the Website, Application and/or the
                Application Functions;
              </p>
              <p>
                (ii) the Website, Application and/or the Application Functions
                being available at all times, uninterrupted or error-free, or
                that defects will be corrected or that the Website, Application
                and/or the Application Functions and any related computer system
                is and will be free of all viruses and/or other harmful
                elements;
              </p>
              <p>
                (iii) the Website, Application and/or the Application Functions
                and any related computer system is and will be free from any
                unauthorised access, intervention, hacking, sabotage, fraud or
                infiltration by third parties; and
              </p>
              <p>
                (iv) the Website, Application and/or the Application Functions
                being compatible or working with any third-party software,
                applications or third-party services.
              </p>
              <p>7.2 Telepharmacare and its partners are not liable for:</p>
              <p>
                7.2.1 the potential and inherent risks associated with remote
                medication and healthcare consultation through a Device,
                including without limitation: (i) the information transmitted by
                you or the Healthcare Provider may not be sufficient or accurate
                (e.g. poor lighting or sound) to allow for appropriate
                healthcare decision making, thus necessitating consultation with
                a pharmacist in person; (ii) reliance on self-measurement and
                reporting of symptoms, including body temperature, blood
                pressure and weight, may result in inaccurate evaluation; (iii)
                a lack of access to all your health records; and/or (iv)
                software, hardware, or data transmission problems or failures
                may impede, or cause disputes or delays in the evaluation;
              </p>
              <p>
                7.2.2 any loss caused to or damage incurred or suffered by you
                or any person by reason of or arising from or as a consequence
                of any use of your account information and/or the Platforms or
                Services, performing any transactions, purchase and/or
                arrangement of delivery of Product, and obtaining and/or making
                any payment of any monies belonging to you;
              </p>
              <p>
                7.2.3 any loss caused to or damage incurred or suffered by you
                or any person by reason of your erroneous, wrongful or
                fraudulent uploading of information, images, laboratory test
                results or diagnostic images, or provision of information by you
                to the Platform or the Healthcare Provider for the purposes of
                your use of the Platform or the Services;
              </p>
              <p>
                7.2.4 any loss, damage or liability which may arise in
                connection to Services offered or Healthcare Products. All
                indirect and/or intangible damage, including without limitation,
                loss of income and consequential damages is excluded from
                Telepharmacare’s liability;
              </p>
              <p>
                7.2.5 for the actions or inactions of any providers of Services
                in relation to you, your activities or for or in connection with
                Services; and
              </p>
              <p>
                7.2.6 any suspension, discontinuation, upgrade or modification
                to the Application or Application Functions which we may carry
                out from time to time without giving any reason or prior notice
                to you which affects your use of the Services, Application
                and/or the Application Functions
              </p>
              <p>
                The exclusions and/or limitations of liability in this Agreement
                shall not apply to the extent that such exclusions and/or
                limitations are prohibited by applicable law, including
                liability for death or personal injury arising from
                Telepharmacare’s negligence.
              </p>
              <h5>8. INDEMNITY</h5>
              <p>
                8.1 You agree to fully indemnify and hold harmless
                Telepharmacare, our affiliates, related companies, members,
                officers, employees, agents, partners and service providers
                (collectively, the "Indemnitees") from and against any claim,
                demand, loss, damage, cost, or liability (including legal fees)
                which any of the Indemnitees may suffer or suffers in connection
                with or arising from (a) any information or content which you
                submit, post, transmit, communicate, send, publish, upload or
                otherwise make available through the Application; (b) your
                breach of this Agreement and any terms relating to your use of
                the Application; (c) your access or use of the Application; (d)
                any action taken by us either as part of our investigation of
                any suspected breach of this Agreement or as a result of our
                finding or decision that a breach of this Agreement has
                occurred; and (e) your breach of any rights of any other person
              </p>
              <h5>9. LIMITATION OF LIABILITY</h5>
              <p>9.1 To the maximum extent allowed under applicable law:</p>
              <p>
                9.1.1 the Indemnitees shall not be liable for any claim, damage
                or loss of any kind of any nature whatsoever caused and
                howsoever arising as a result (direct or indirect) of or
                otherwise in connection with your use of or reliance on the
                Website and/or Application, including but not limited to any
                claim, damage or loss suffered (whether incidental, special,
                indirect or consequential) as a result of or in connection or in
                reliance of any content or any other information made available
                or accessed on or through the Application or contained in or
                available from the Application or your use or reliance on any
                products or services available on or accessed via the Website
                and/or Application and/or the Application Functions or any
                infringement of any rights arising in connection therewith,
                including without limitation any loss of data, profits,
                goodwill, anticipated savings, reputation, business or business
                opportunity, regardless of the cause thereof and even if we have
                been advised of the possibility thereof; and
              </p>
              <p>
                9.1.2 in no event shall the Indemnitees be liable for personal
                injury, or any incidental, special, indirect or consequential
                damages whatsoever, including, without limitation, damages for
                loss of profits, corruption or loss of data, failure to transmit
                or receive any data, business interruption or any other
                commercial damages or losses, arising out of or related to your
                use or inability to use the Application or any third party
                software or applications in conjunction with the Application,
                howsoever caused, regardless of the theory of liability
                (contract, tort or otherwise) and even if we have been advised
                of the possibility of such damages.
              </p>
              <p>
                9.2 In the event that Telepharmacare is held liable,
                Telepharmacare shall only be responsible for direct losses or
                damages which may be reasonably expected to result from the
                circumstances and provided such losses and damages were caused
                by Telepharmacare’s gross negligence or wilful default.
              </p>
              <h5>10. TERMINATION</h5>
              <p>
                10.1 We have the right to terminate or suspend your account in
                our sole and absolute discretion without prior notice to you,
                for any reason at any time. Notwithstanding any such termination
                or suspension, you remain liable for the outstanding amounts
                incurred by you.
              </p>
              <p>
                10.2 You may deactivate/terminate your Account at any time, for
                any reason by sending us an email to
                <Link to="support@telepharmacare.com">
                  support@telepharmacare.com
                </Link>
              </p>
              <p>10.3 After such termination, Telepharmacare:</p>
              <p>
                10.3.1 will have no further obligation to provide the Services;
                and
              </p>
              <p>
                10.3.2 subject to applicable law and the Privacy Policy,
                reserves the right to retain, delete or destroy all information,
                communications and materials stored, posted or uploaded to the
                Application pursuant to its internal record retention and/or
                content destruction policies, including, but not limited to,
                your personal data and medication records.
              </p>
              <p>
                10.4 Upon termination, you will cease to use the Platforms
                immediately.
              </p>
              <p>
                10.5 To the extent permitted by applicable law, the disclaimers,
                indemnities, limitations on liability, termination and your
                representations and warranties shall survive any termination of
                this Agreement.
              </p>
              <h5>11. FORCE MAJEURE</h5>
              <p>
                Neither Telepharmacare nor any providers of Services under this
                Agreement shall be liable for delay in performing obligations or
                for failure to perform obligations under this Agreement if the
                delay or failure resulted from events, causes and circumstances
                beyond its reasonable control, including but not limited to,
                governmental acts (including directives issued by regulators and
                amendments to legislation), shut down or failure of
                telecommunications facilities or networks, failures or acts of
                application distributors, failure of information technology or
                telecommunications equipment or facilities, hacker attacks,
                denial of service attacks, virus or other malicious software
                attacks or infections, power failures, epidemics, flood, fire,
                explosion, accident, civil commotion, industrial dispute, or
                impossibility of obtaining materials, pandemics or war.
              </p>
              <h5>12. GENERAL</h5>
              <p>
                12.1 This Agreement, the Privacy Policy and the documents in it,
                constitutes the entire agreement and understanding between you
                and Telepharmacare relating to the Application or websites and
                you have not entered into this Agreement in reliance upon any
                representation, warranty or undertaking of Telepharmacare which
                is not set out in this Agreement. Nothing in this Agreement
                shall however operate to limit or exclude liability for fraud.
              </p>
              <p>
                12.2 We may from time to time update these terms of this
                Agreement by posting amendments at the Website or on the
                Application. By your continuing use of the Website and/or
                Application after any such amendment, you agree to be bound by
                this Agreement as so amended.
              </p>
              <p>
                12.3 The illegality, invalidity or unenforceability of any
                provision of this Agreement under the law of any jurisdiction
                shall not affect its legality, validity or enforceability under
                the law of any other jurisdiction nor the legality, validity or
                enforceability of any other provision.
              </p>
              <p>
                12.4 No failure on the part of any party to exercise, and no
                delay on its part in exercising, any right or remedy under this
                Agreement will operate as a waiver thereof, nor will any single
                or partial exercise of any right or remedy preclude any other or
                further exercise thereof or the exercise of any other right or
                remedy. The rights provided in this Agreement are cumulative and
                not exclusive of any rights or remedies provided by law.
              </p>
              <p>
                12.5 We may seek immediate injunctive relief if we make a good
                faith determination that a breach or non-performance is such
                that a temporary restraining order or other immediate injunctive
                relief is an appropriate or adequate remedy.
              </p>
              <p>
                12.6 Telepharmacare may give notice by means of a general notice
                on the Application, or by electronic mail to your email address,
                by SMS to your mobile phone number, or by written communication
                sent by registered mail to your address. Your contact details
                for such notices shall be based on your details in
                Telepharmacare’s records. You may only give notice to us in
                writing sent to our designated address or e-mail address.
              </p>
              <p>
                12.6.1 Notices shall be deemed to have been duly given and
                received upon the expiration of 72 (seven-two) hours after
                mailing to your post address, 2 (two) hours after sending (if
                sent by email), provided that no automated message is received
                stating that the email has not been delivered, or immediately
                (if sent by SMS). Notices on the Application shall be deemed to
                have been received by you whether or not you actually access the
                notice. While we endeavour to respond promptly to notices from
                you, we cannot guarantee that we will always respond with
                consistent speed.
              </p>
              <p>
                12.6.2 This clause does not apply to notices issued in respect
                of legal proceedings.
              </p>
              <p>
                12.7 The provisions of the Agreement are severable, and if any
                provision, or any portion thereof, is determined by a competent
                court or tribunal to be illegal, invalid or unenforceable for
                any reason, any remaining portion of that provision, and all
                other provisions of the Agreement, shall remain valid and
                enforceable to the fullest extent permitted by law in order to
                give effect to the Parties' intentions.
              </p>
              <p>
                12.8 We reserve the right to delegate or sub-contract the
                performance of any of our Application Functions or Services or
                obligations in connection with Telepharmacare to any related
                company, service provider, subcontractor and/or agent on such
                terms as we may deem appropriate.
              </p>
              <p>
                12.9 You may not assign your rights or transfer your obligations
                under this Agreement without our prior written consent but we
                may assign our rights or transfer our obligations under this
                Agreement to any affiliate or third party at our sole
                discretion.
              </p>
              <p>
                12.10 Save for the related companies of Telepharmacare, partners
                of Telepharmacare and as expressly provided in this Agreement
                including without limitation as referred to in this Agreement, a
                person who is not a party to this Agreement shall have no right
                to enforce or enjoy the benefit of any term of this Agreement.
                Notwithstanding any term of this Agreement, the consent of any
                person who is not a party to this Agreement is not required to
                rescind or vary this Agreement at any time.
              </p>
              <p>
                12.11 In the event that this Agreement is executed or translated
                in any language other than English, to the extent allowed under
                law, the English language version of this Agreement shall govern
                and shall take precedence over others language versions.
              </p>
              <h5>13. GOVERNING LAW AND JURISDICTION</h5>
              <p>
                This Agreement shall be governed by, and construed in accordance
                with, the laws of Sweden. The Parties shall attempt in good
                faith to resolve promptly any dispute arising out of or in
                connection with this Agreement through amicable negotiations
                (which shall be commenced by either party giving written notice
                to the other). In the event that such dispute cannot be resolved
                by amicable negotiations within thirty (30) days of either party
                giving written notice to the other party that a dispute has
                arisen, the dispute shall be submitted by either party for
                resolution by the courts in Sweden which courts shall have
                exclusive jurisdiction.
              </p>
              <p>Questions?</p>
              <p>
                If you have any questions on this Agreement, our policies or
                FAQs, or if you have any difficulties with the Website and/or
                Application or payment, please contact us at 
                <Link to="https://telepharmacare.com">
                  https://telepharmacare.com
                </Link>
                or email at
                <Link to="mailtosupport@telepharmacare.com">
                  support@telepharmacare.com
                </Link>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
