import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BlogComments,
  addComment,
  findblogbyId,
  getBlogs,
} from "../Redux/Actions/UserActions";
import moment from "moment";
import { toast } from "react-toastify";

export default function BlogView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const blog = useSelector((state) => state.blogdata.blog);
  const blogs = useSelector((state) => state.blogdata.blogList);
  console.log(blogs?.data);
  const [name, setName] = useState(null);
  const [blogComment, setBlogComments] = useState(null);
  const [show, setShow] = useState(false);
  const [nameerr, setNameerr] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailerr, setEmailerr] = useState(null);
  const [comment, setComment] = useState(null);
  const [commenterr, setCommenterr] = useState(null);
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    dispatch(findblogbyId({ blog_id: id }));
  }, [id, flag]);

  useEffect(() => {
    dispatch(getBlogs());
  }, [id]);

  const handleSave = () => {
    let isvalid = true;

    if (!name) {
      setNameerr("Required*");
      isvalid = false;
    }
    if (!email) {
      setEmailerr("Required*");
      isvalid = false;
    }
    if (!comment) {
      setCommenterr("Required*");
      isvalid = false;
    }
    if (isvalid) {
      dispatch(
        addComment({
          blogs_id: id,
          blogs_comments: comment,
          email: email,
          name: name,
        })
      ).then((res) => {
        if (res?.payload?.status == "Success") {
          toast.success(res?.payload?.message);
          setName("");
          setComment("");
          setEmail("");
          setFlag(!flag);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      let data = await dispatch(BlogComments({ blog_id: id,status:"1" }));
      setBlogComments(data);
    };

    fetchdata();
  }, [id,show]);

  console.log(blog?.data?.comments);

  return (
    <Layout>
      <section className="page-mt">
        <Container>
          <Row>
            <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
              <div className="left-content-post">
                <div className="blog-heading-layout">
                  <div className="blog-date-post">
                    <span className="day">
                      {moment(blog?.data?.blog?.createdAt).format("Do")}
                    </span>
                    <span className="month">
                      {moment(blog?.data?.blog?.createdAt).format("MMMM")}
                    </span>
                  </div>
                  <h1 className="blog-title-post">{blog?.data?.blog?.title}</h1>
                </div>

                <div className="blog-lg-img">
                  <picture>
                    <img
                      className="img-fluid"
                      src={`https://api.telepharmacare.com/public/production/${blog?.data?.blog?.blog_image}`}
                      alt="Share Images Without Losing Quality With Imghippo"
                    />
                  </picture>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blog?.data?.blog?.description,
                  }}
                  className="blog-content-gry  mt-4"
                ></div>
                {/* <div className="black-lg-text-heading">
                  Author:Jennifer Brien
                </div> */}
                {/* <!-- comment- part --> */}
                <div className="blog-comment-form-part">
                  <div className="black-lg-text-heading totalpost">
                    {/* <div className="d-flex gap-2 " > */}
                      <span onClick={()=>{
                         setShow(true);
                      }} style={{cursor:"pointer"}} >{`Comments (${
                        blog?.data?.commentCount
                          ? blog?.data?.commentCount
                          : "0"
                      })`}</span>
                      {/* <a style={{fontSize:"15px"}}>View Comments</a> */}
                    </div>
                  {/* </div> */}
                  <ul className="comment-show comments"></ul>
                  {/* <!-- leave comment --> */}
                  <div className="leave-comment-part">
                    <div className="black-lg-text-heading">Leave a comment</div>

                    <form method="POST" action="" className="comment-form">
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3">
                          <label for="">Full Name</label>
                          <input
                            type="text"
                            id=""
                            name="name"
                            className="form-control full_name"
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameerr("");
                            }}
                            value={name}
                          />
                          <div className="text-danger">{nameerr}</div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3">
                          <label for="">Email Address</label>
                          <input
                            type="email"
                            id=""
                            name="email"
                            className="form-control email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailerr("");
                            }}
                            value={email}
                          />
                          <div className="text-danger">{emailerr}</div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mb-3">
                          <label for="">Comment</label>

                          <textarea
                            name="comment"
                            id=""
                            className="form-control post_comment"
                            onChange={(e) => {
                              setComment(e.target.value);
                              setCommenterr("");
                            }}
                            value={comment}
                          ></textarea>
                          <div className="text-danger">{commenterr}</div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mb-3">
                          <button
                            onClick={handleSave}
                            type="button"
                            className="submit-btn-form submit_comment"
                            data-id="8"
                          >
                            Post Comment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
              <div className="right-side-content">
                <div className="recent-heading">RECENT</div>
                <ul className="blog-post-li">
                  {blogs?.data?.slice(0, 10)?.map((x) => {
                    return (
                      <li onClick={() => navigate(`/blog-view/${x?._id}`)}>
                        <div className="side-blog-img">
                          <div>
                            <picture>
                              <source
                                media="(max-width: 480px)"
                                srcset="https://i.imghippo.com/files/JDYuo1715061628.png"
                              />
                              <img
                                style={{ cursor: "pointer" }}
                                src={`https://api.telepharmacare.com/public/production/${x?.blog_image}`}
                                alt="Img"
                                width="50"
                                height="50"
                              />
                            </picture>
                          </div>
                        </div>
                        <div className="post-info">
                          <Link to="#">{x?.title}</Link>
                          <div className="post-meta">
                            {moment(x?.createdAt).format("MMMM Do, YYYY")}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="promocode-add social-form-new">
            <div className="row">
              {/* <div className="form-group mb-3 col-md-6">
                <label className="font-set">Email</label>
                <p>sylviahuber@mail.com</p>
              </div> */}
              {/* <div className="form-group mb-3 col-md-6">
                <label className="font-set">Phone number</label>
                <p>+44 987 654 78956</p>
              </div> */}
              {blogComment?.data?.data?.map((comment, index) => {
                return (
                  <>
                    <div className="form-group mb-3 col-md-12">
                      <div className="d-flex ">
                        <p style={{ marginRight: "7px"  }}><b>{index + 1}</b>. </p>
                        <label style={{fontWeight:"bold"}} className="font-set">{comment?.name}</label>
                      </div>

                      <p style={{ marginLeft: "14px" }}>{comment?.comments}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Reject
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Accept
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Layout>
  );
}
