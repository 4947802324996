import { createSlice } from "@reduxjs/toolkit";
import { findblogbyId, getBlogs, landingPage } from "../Actions/UserActions";

export const landingPageSlice = createSlice({
  name: "landingPageSlice",
  initialState: {
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(landingPage.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
