import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";
// import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Layout from "../Components/Layout";
import { ContactUs, landingPage } from "../Redux/Actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserCountdown from "../Components/UserCountDown";

const validateYupSchema = yup.object().shape({
  name: yup.string().required(<span style={{ color: "red" }}>Required</span>),
  email: yup
    .string()
    .email(<span style={{ color: "red" }}>Invalid Email Address</span>)
    .required(<span style={{ color: "red" }}>Required</span>),
  message: yup
    .string()
    .required(<span style={{ color: "red" }}>Required</span>),
});

export default function Home() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.landingpageadata.data);
  console.log(data);

  // ===================================landing page content api=========================================================

  useEffect(() => {
    dispatch(landingPage());
  }, []);

  AOS.init();
  const optionsnewslider = {
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,

    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  const optionsslider = {
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    stagePadding: 10,
    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  return (
    <Layout>
      <section className="banner_section" id="home">
        <Container>
          <Row>
            <Col
              lg={6}
              className=""
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner_text">
                <h1>
                  <span className="green_color">Find your </span>
                  <br />
                  pharmacist with
                  <br />
                  ease!
                </h1>

                <p>
                  At Telepharmacare we are committed to breaking down barriers
                  and ensuring that pharmacists' guidance on medications is
                  accessible to everyone. By focusing on affordability and
                  inclusion, we want to help people make smart decisions about
                  their health and well-being.
                </p>
              </div>
              <div className="action-btns mt-4">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      target="_blank"
                      className="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                    >
                      <img
                        src={require("../images/ios-down.svg").default}
                        alt="icon"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      target="_blank"
                      to="#"
                      className="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                    >
                      <img
                        src={require("../images/play-store.svg").default}
                        alt="icon"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={6} className="">
              <div className="banner_image">
                <img
                  className="moving_animation"
                  src={require("../images/banner-image1.png")}
                  alt="image"
                  data-aos="fade-left"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="row_am trusted_section pb-0">
        <Container>
          <section className="row_am about_app_section">
            <Container>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="about_img">
                    <div className="frame_img">
                      <img
                        className="moving_position_animatin"
                        src={require("../images/about-frame1.png")}
                        alt="image"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="about_text">
                    <div
                      className="section_title"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      data-aos-delay="100"
                    >
                      <h2>
                        Some awesome words{" "}
                        <span>about Telepharmacare app.</span>
                      </h2>
                    </div>

                    <ul
                      className="app_statstic"
                      id="counter"
                      data-aos="fade-in"
                      data-aos-duration="1500"
                    >
                      <li>
                        <div className="icon">
                          <img
                            src={require("../images/download1.svg").default}
                            alt="image"
                          />
                        </div>
                        <div className="text">
                          <p>
                            <span className="counter-value">
                              {/* {data?.data?.total_users} */}
                              <UserCountdown
                                totalUsers={data?.data?.total_users}
                              />
                            </span>
                            <span></span>
                          </p>
                          <p>App Download</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <img
                            src={require("../images/reviews1.svg").default}
                            alt="image"
                          />
                        </div>
                        <div className="text">
                          <p>
                            <span className="counter-value">
                              {/* {data?.data?.total_reviews} */}
                              <UserCountdown
                                totalUsers={data?.data?.total_reviews}
                              />
                            </span>
                            <span></span>
                          </p>
                          <p>Total Reviews</p>
                        </div>
                      </li>
                    </ul>

                    <a
                      href="#contact"
                      className="btn puprple_btn"
                      data-aos="fade-in"
                      data-aos-duration="1500"
                    >
                      Contact Us
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Container>
      </section>

      <section
        className="promo-section row_am position-relative overflow-hidden pb-4"
        id="categories"
      >
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-heading">
                <h2>Categories</h2>
                <p>
                  You can choose the categories in which you want consultation
                  from our competent healthcare professionals. Below are some of
                  the categories we offer at Telepharmacare.
                </p>
              </div>
            </Col>
          </Row>
          <div className="screen_slider">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={true}
              {...optionsnewslider}
            >
              <div className="item">
                <div className="screen_frame_img">
                  <img src={require("../images/stomach.png")} alt="image" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img
                    src={require("../images/mental-health.png")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={require("../images/skin.png")} alt="image" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={require("../images/training.png")} alt="image" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={require("../images/weight-loss.png")} alt="image" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={require("../images/sleep.png")} alt="image" />
                </div>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </section>

      <section className="row_am modern_ui_section mb-5" id="our-mission">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="ui_text">
                <div
                  className="section_title aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    <span className="green_color">Our </span>Mission
                  </h2>
                  <p>
                    Our mission is to preserve and improve patients' health by
                    providing a safe, efficient, and high-quality digital
                    healthcare medication platform across the globe through our
                    state-of-the-art technology, competent healthcare
                    professionals, and pharmaceutical services that meet
                    customer expectations.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div
                className="ui_images aos-init aos-animate"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="left_img">
                  <img
                    className="moving_position_animatin"
                    src={require("../images/mission (1).svg").default}
                    alt="image"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="row_am modern_ui_section mb-5" id="our-vision">
        <Container>
          <Row>
            <Col lg={6}>
              <div
                className="ui_images aos-init aos-animate"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="left_img">
                  <img
                    className="moving_position_animatin"
                    src={require("../images/vector_illustration.svg").default}
                    alt="image"
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="ui_text">
                <div
                  className="section_title aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    <span className="green_color">Our </span>Vision
                  </h2>
                  <p>
                    Our vision is to make sure that everyone, regardless of
                    where they live or what their financial situation is, has
                    access to affordable and reliable medication advice and the
                    right medical care.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="row_am how_it_works" id="process">
        <Container>
          <div className="how_it_inner">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <h2>
                <span>WTF?</span> How do I do it?
              </h2>

              <p className="mb-5">
                Calm down Amigo! Hold the Phone. If an Island Boy can do it, so
                can you. It is easy. It only takes 3 simple steps.
              </p>
            </div>
            <div className="step_block">
              <ul>
                <li>
                  <div
                    className="step_text"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    <h4>Welcome to Telepharmacare</h4>

                    <p>
                      Download the app on App Store or Get it on Google Play and
                      sign up for an account. And just follow the instructions
                      on the screen.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>01</h3>
                  </div>
                  <div
                    className="step_img"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <img src={require("../images/steps_1.png")} alt="image" />
                  </div>
                </li>

                <li>
                  <div
                    className="step_text"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <h4>
                      Find your pharmacist and make an appointment with ease!
                    </h4>

                    <p>
                      At this point, you can select the pharmacist you wish to
                      schedule an appointment with based on your criteria and
                      preference or based on previous customer reviews.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>02</h3>
                  </div>
                  <div
                    className="step_img"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    <img src={require("../images/step_2.png")} alt="image" />
                  </div>
                </li>

                <li>
                  <div
                    className="step_text"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    <h4>Choose Consultant Type</h4>

                    <p>
                      At the last step before your appointment, you get to
                      choose the type of consultation you wish for from our
                      competent healthcare professionals.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>03</h3>
                  </div>
                  <div
                    className="step_img"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <img src={require("../images/step_3.png")} alt="image" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      <section className="row_am testimonial_section" id="testimonial">
        <Container>
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="300"
          >
            <h2>
              What Random <span>People are saying…</span>
            </h2>

            <p>You’re welcome for changing your life for the better world. </p>
          </div>
          <div
            className="testimonial_block"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div id="testimonial_slider">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav={false}
                dots={true}
                {...optionsslider}
              >
                <div className="item">
                  <div className="testimonial_slide_box">
                    <div className="rating">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <p className="review">
                      “ Great app.. now i can't find the download any help would
                      be appreciated ”
                    </p>
                    <div className="testimonial_img">
                      <img
                        src={require("../images/testimonial_user1.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Shayna John</h3>
                    <span className="designation">Careative inc</span>
                  </div>
                </div>

                <div className="item">
                  <div className="testimonial_slide_box">
                    <div className="rating">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <p className="review">
                      “ Very easy to use. Everything is well described. ”
                    </p>
                    <div className="testimonial_img">
                      <img
                        src={require("../images/testimonial_user2.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Willium Den</h3>
                    <span className="designation">Careative inc</span>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial_slide_box">
                    <div className="rating">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                            fill="#18DF80"
                            stroke="#18DF80"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <p className="review">
                      “ Waited a long time for this and it has not disappointed!
                      A huge benfit to the society and i recommend all to use
                      this ”
                    </p>
                    <div className="testimonial_img">
                      <img
                        src={require("../images/testimonial_user3.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Cyrus Stephen</h3>
                    <span className="designation">Careative inc</span>
                  </div>
                </div>
              </OwlCarousel>
            </div>
            <div className="total_review">
              <div className="rating">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                      fill="#18DF80"
                      stroke="#18DF80"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                      fill="#18DF80"
                      stroke="#18DF80"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                      fill="#18DF80"
                      stroke="#18DF80"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                      fill="#18DF80"
                      stroke="#18DF80"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M8.52917 1L10.8557 5.71326L16.0583 6.4737L12.2938 10.1404L13.1822 15.3205L8.52917 12.8735L3.87614 15.3205L4.76459 10.1404L1 6.4737L6.20266 5.71326L8.52917 1Z"
                      fill="#18DF80"
                      stroke="#18DF80"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <p>5.0 / 5.0</p>
              </div>
              <h3>2578</h3>
            </div>

            <div className="avtar_faces">
              <img
                src={require("../images/avtar_testimonial.png")}
                alt="image"
              />
            </div>
          </div>
        </Container>
      </section>

      <section className="row_am free_app_section mb-0 pb-0" id="getstarted">
        <Container>
          <div
            className="free_app_inner"
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <Row>
              <Col lg={6}>
                <div className="free_text">
                  <div className="section_title">
                    <h2>
                      Book your appointment by downloading Telepharmacare app
                      now!
                    </h2>
                    <p>
                      At Telepharmacare we are committed to breaking down
                      barriers and ensuring that pharmacists' guidance on
                      medications is accessible to everyone.
                    </p>
                    <p>Your one-stop solution for your health!</p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <Link to="#">
                        <img
                          src={require("../images/ios-down.svg").default}
                          alt="image"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img
                          src={require("../images/play-store.svg").default}
                          alt="image"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={6}>
                <div className="free_img">
                  <img
                    src={require("../images/about-frame1.png")}
                    alt="image"
                  />
                  <img
                    className="mobile_mockup"
                    src={require("../images/download-screen02.png")}
                    alt="image"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="contact_page_section mt-5 mb-4 row_am" id="contact">
        <Container>
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>Ready to get started?</h2>
              </div>
              <Formik
                initialValues={{ name: "", email: "", message: "" }}
                validationSchema={validateYupSchema}
                onSubmit={async (values, { resetForm }) => {
                  console.log(values);
                  try {
                    const data = await dispatch(ContactUs(values));
                    if (data?.payload?.data?.status === 200) {
                      toast.success(data?.payload?.data?.message);
                      resetForm();
                    }
                    console.log(data);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form id="contactForm">
                    <div className="form-group">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        className="form-control"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.name}
                      />
                      <ErrorMessage name="name" component="div" />
                    </div>
                    <div className="form-group">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className="form-control"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage name="email" component="div" />
                    </div>
                    <div className="form-group">
                      <Form.Control
                        as="textarea"
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        onChange={handleChange}
                        value={values.message}
                      ></Form.Control>
                    </div>
                    <ErrorMessage name="message" component="div" />

                    <div className="form-group mb-0">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn puprple_btn"
                      >
                        SEND MESSAGE
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="contact_info">
              <div className="section_title">
                <h2 className="mb-4">Want To Reach Out?</h2>
              </div>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img
                      src={require("../images/location_icon.svg").default}
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Company Location</span>
                    <p>Sweden</p>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img
                      src={require("../images/message_icon.svg").default}
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Email Address</span>
                    <Link to="mailto:wecare@telepharmacare.com">
                      wecare@telepharmacare.com
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
}
