import React,{useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function Footer() {

  const navigate = useNavigate()
  const [scroll, setScroll] = useState(false)

  const handleArrow = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer>
      <div className="top_footer" id="contact">
        <Container>
          <Row>
            <Col lg={4} md={6} Col={12}>
              <div className="abt_side">
                <div className="logo">
                  {" "}
                  <Link to="/">
                    {" "}
                    <img
                      src={require("../images/logo-update.svg").default}
                      alt="image"
                    />
                  </Link>
                </div>
                <ul>
                  <li>
                    <Link to="#">
                      Be Part Of The Digital Pharmaceutical Health Revolution
                      With Us At Telepharmacare!
                    </Link>
                  </li>
                </ul>
                <ul className="social_media">
                  <li>
                    <Link
                      to="https://twitter.com/telepharmacare"
                      target="_blank"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/@telepharmacare"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/telepharmacare"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.facebook.com/telepharmacare"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.tiktok.com/@telepharmacare">
                      <i className="fa-brands fa-tiktok"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/telepharmacare"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="links">
                <ul>
                  <li>
                    <a
                      href="#categories"
                      duration={200}
                      offset={-300}
                      className="nav-link cursor-pointer"
                    >
                      Categories
                    </a>
                  </li>
                  <li>
                    <a
                      href="#process"
                      duration={200}
                      offset={-0}
                      className="nav-link cursor-pointer"
                    >
                      Process
                    </a>
                  </li>
                  <li>
                    <a
                      href="#our-mission"
                      duration={200}
                      offset={-300}
                      className="nav-link cursor-pointer"
                    >
                      Mission
                    </a>
                  </li>
                  <li>
                    <a
                      href="#our-vision"
                      duration={200}
                      offset={-200}
                      className="nav-link cursor-pointer"
                    >
                      Vision
                    </a>
                  </li>
                  <li>
                    <Link to="/blog-list">Pharma News</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="links">
                <ul>
                  <li>
                    <a
                      href="#testimonial"
                      duration={200}
                      offset={-100}
                      className="nav-link cursor-pointer"
                    >
                      Testimonial
                    </a>
                  </li>

                  <li>
                    <a
                      href="#contact"
                      duration={200}
                      offset={-300}
                      className="nav-link cursor-pointer"
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">Cookie Policy</Link>
                  </li>
                  <li onClick={()=>navigate("/terms-and-conditions")}>
                    <Link 
                    // to="/terms-and-conditions"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={2} md={6}>
              <div className="try_out">
                <h3>Let’s Try Out</h3>
                <ul className="app_btn">
                  <li>
                    <Link to="#" target="_blank">
                      <img
                        src={require("../images/ios-down.svg").default}
                        alt="image"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" target="_blank">
                      <img
                        src={require("../images/play-store.svg").default}
                        alt="image"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_footer">
        <Container>
          <Row>
            <Col lg={6}>
              <p>Copyright 2024 Telepharmacare.com I All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="go_top"  onClick={handleArrow}>
        <Link duration={200} offset={-0}>
          <img src={require("../images/go_top.svg").default} alt="image" />
        </Link>
      </div>
    </footer>
  );
}
