import axios from "axios"

export const API = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
    headers: {
        token: localStorage.getItem("token"),
    },
});


 API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return error;
    }
);