import React, { useState, useEffect } from 'react';

const UserCountdown = ({ totalUsers }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let currentCount = 0; // Initialize a separate variable to track count

    const interval = setInterval(() => {
      if (currentCount <= totalUsers) {
        setCount(currentCount); // Update count from the separate variable
        currentCount += 1; // Increment the separate variable
      } else {
        clearInterval(interval); // Stop the interval once we reach the totalUsers
      }
    }, 100); // Interval time in milliseconds (adjust as needed)

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [totalUsers]);

  return (
    <div>
      <p>{count}</p>
    </div>
  );
};

export default UserCountdown;
