import React, { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
export default function Header() {

  const [scroll, setScroll] = useState(false);
  useEffect(() => {

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  return (
    <header className={scroll ? "fix_style fixed" : "navbar-top"}>
      <Container>
        <Navbar expand="lg" className="navbar-brand m-0">
          <Container className="p-0">
            <Navbar.Brand>
              <Link to="/">
                <img src={require("../images/logo-update.svg").default} />
              </Link>
              {/* <img src={require("../images/logo-update.svg").default} /> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Link
                  to="/"
                  duration={200}
                  offset={-0}
                  className="nav-link cursor-pointer"
                >
                  Home
                </Link>
                <a
                  href="#categories"
                  duration={200}
                  offset={-300}
                  className="nav-link cursor-pointer"
                >
                  Categories
                </a>
                <a
                  href="#our-mission"
                  duration={200}
                  offset={-300}
                  className="nav-link cursor-pointer"
                >
                  Our Mission
                </a>
                <a
                  href="#our-vision"
                  duration={200}
                  offset={-200}
                  className="nav-link cursor-pointer"
                >
                  Our Vision
                </a>
                <a
                  href="#process"
                  duration={200}
                  offset={-0}
                  className="nav-link cursor-pointer"
                >
                  Process
                </a>
                <a
                  href="#testimonial"
                  duration={200}
                  offset={-100}
                  className="nav-link cursor-pointer"
                >
                  Testimonial
                </a>
                <a
                  href="#contact"
                  duration={200}
                  offset={-300}
                  className="nav-link cursor-pointer"
                >
                  Contact
                </a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </header>
  );
}
