import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
export default function PrivacyPolicy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <section className="page-mt pt-5">
        <Container>
          <div class="container">
            <div class="privacy_text">
              <div class="header-logo text-center">
                <Link class="main-logo link" to="/">
                  <img src={require("../images/logo-update.svg").default} />
                </Link>
              </div>
              <h3>PRIVACY AND DATA PROTECTION POLICY (“PRIVACY POLICY”)</h3>
              <p>Effective date: 2023-04-23</p>
              <p>
                This Privacy Policy sets out the basis on which Telepharmacare
                (TPC) and our related companies (“TPC”, “we”, “us”, or “our”)
                may collect, use, disclose or otherwise process your personal
                data when you use our website at{" "}
                <Link to={"https://telepharmacare.com"}>
                  {" "}
                  https://telepharmacare.com
                </Link>
                , and/or the Telepharmacare mobile applications (“Application”)
                , visit any of the pharmacy owned or operated by TPC and/or
                participate in any health screenings, vaccinations or any other
                programmes, activities or events which TPC organises, sponsors
                or is otherwise involved in, or otherwise use any services
                provided by TPC (collectively, “Platforms”) in accordance with
                the General Data Protection Regulation (“GDPR”). This Privacy
                Policy applies to personal data in our possession or under our
                control, including personal data in the possession of
                organisations which we have engaged to collect, use, disclose or
                process personal data for our purposes.
              </p>
              <p>
                If you do not agree to this Privacy Policy and our Terms and
                Conditions, please do not use any of the Platforms. Your
                continued use of the Platforms and our services constitutes your
                acknowledgement and acceptance of this Privacy Policy and our
                Terms and Conditions.
              </p>

              <h4>PERSONAL DATA</h4>

              <p>1. As used in this Privacy Policy:</p>

              <p>
                “customer” means an individual who (a) has contacted us through
                any means (such as through any of the Platforms) in relation to
                the goods or services available on the Platforms, or (b) may, or
                has, entered into a contract with us for the supply of any goods
                or services by us.
              </p>

              <p>
                “Personal data” means data, whether true or not, about an
                individual who can be identified: (a) from that data; or (b)
                from that data and other information to which we have or are
                likely to have access.
              </p>

              <p>
                2. Depending on the nature of your interaction with us, some
                examples of personal data which we may collect from you include:
              </p>

              <p>
                a. Your name, identification numbers such as, Driving licence,
                Passport Number, residential address, email address, telephone
                number, nationality, gender and date of birth;
              </p>

              <p>
                b. Personally-identifiable medical and health-related
                information which you provide to us (including information or
                records relating to your medication or health history, medical
                claims, health status, laboratory testing results, photographs);
              </p>

              <p>
                c. Personally-identifiable medication and health information
                about you prepared by healthcare professionals/treatment
                providers/diagnostic and medical laboratories who provide
                medical or healthcare services and products to you via the
                Platforms (including medication records, treatment and
                examination notes, laboratory testing results or diagnostic
                images);
              </p>

              <p>
                d. Billing information that you provide us (e.g., credit card or
                online payment system account information);
              </p>

              <p>
                e. Information about the computer or mobile device you are
                using;
              </p>

              <p>f. Geographical location or address; or</p>

              <p>
                g. Other information which you may input into the Platforms or
                related services.
              </p>

              <p>
                3. Other terms used in this Privacy Policy shall have the
                meanings given to them in the GDPR (where the context so
                permits).
              </p>

              <h4>COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA</h4>

              <p>
                4. We generally do not collect your personal data unless (a) it
                is provided to us voluntarily by you directly or via a third
                party who has been duly authorised by you to disclose your
                personal data to us (your “Authorised Representative”, which
                could include any specialists, diagnostic and medical
                laboratories and other healthcare providers involved in your
                medical care) for the purposes set out in this Privacy Policy,
                or (b) collection and use of personal data without consent is
                permitted or required by the GDPR or other laws. We shall seek
                your consent before collecting any additional personal data and
                before using your personal data for a purpose which has not been
                notified to you (except were permitted or authorised by law).
              </p>

              <p>
                5. We may collect and use your personal data for any or all of
                the following purposes:
              </p>

              <p>
                a. performing obligations in the course of or in connection with
                our provision of the goods and/or services requested by you
                (including the provision of any medication or healthcare
                services, the delivery of products purchased by you);
              </p>

              <p>b. verifying your identity;</p>

              <p>
                c. responding to, handling, and processing queries, requests,
                applications, complaints, and feedback from you;
              </p>

              <p>d. managing your relationship with us;</p>

              <p>d. managing your relationship with us;</p>

              <p>f. processing payment or credit transactions;</p>

              <p>
                g. improving the quality of medication or healthcare services
                through the performance of quality reviews and similar
                activities;
              </p>

              <p>
                h. creating de-identified information which is information which
                is not used or intended to be used to personally identify an
                individual (e.g., aggregate statistics relating to the use of
                the services on the Platforms);
              </p>

              <p>i. notifying you when Platform updates are available;</p>

              <p>
                j. marketing and promoting the Platforms, and the services and
                products offered on the Platforms to you;
              </p>

              <p>k. conveying health and wellness information to you;</p>

              <p>
                l. complying with any applicable laws, regulations, codes of
                practice, guidelines, or rules, or to assist in law enforcement
                and investigations conducted by any governmental and/or
                regulatory authority;
              </p>

              <p>
                m. converting the personal data to anonymised data for the
                purposes of analytics and market research;
              </p>

              <p>
                n. any other purposes for which you have provided the
                information;
              </p>

              <p>
                o. transmitting to any unaffiliated third parties including our
                third-party service providers and agents, and relevant
                governmental and/or regulatory authorities, whether in Sweden or
                abroad, for the aforementioned purposes; and
              </p>

              <p>
                p. any other incidental business purposes related to or in
                connection with the above.
              </p>

              <p>
                {" "}
                In the event we do contact you for any of the above purposes,
                you agree that we may contact you via email or text or by call
                from our app or telephone number provided to us by you or your
                Authorised Representative.
              </p>

              <p>
                5A. We may rely on the “legitimate interests” exception under
                the GDPR to collect, use and disclose personal data without your
                consent for the purposes of detecting and preventing fraud and
                misuse of our services. Such disclosures may be made to your
                insurer or law enforcement, where relevant for such purposes.
              </p>

              <p>
                5B. Giving Consent on behalf of Dependents: Where applicable,
                you agree and confirm that you have the requisite authority and
                can validly give consent on behalf of your dependents for the
                collection, use, disclosure and processing of their personal
                data, including but not limited to, the personal data of any
                child/ward under your care or custody.
              </p>

              <p>
                6. As you use the Website or Application, its services, or
                purchase or arrange for the delivery of products, certain
                information may be passively collected:
              </p>

              <p>
                a. Site Activity Information: We may keep track of some of the
                actions you take on the Website or Application, such as the
                content of searches you perform;
              </p>

              <p>
                b. Access Device and Browser Information: When you access the
                Website from a computer or other device, we may collect
                anonymous information from that device, such as your Internet
                protocol address, browser type, connection speed and access
                times;
              </p>

              <p>
                c. Cookies (you can find more information in our cookies policy
                found on our website or in the application) i.e., small pieces
                of information that a site sends to your browser while you are
                viewing a website: We may use both session Cookies (which expire
                once you close your web browser) and persistent Cookies to make
                the Website and services easier to use, to make our advertising
                better, and to protect both you and TPC. You can instruct your
                browser, by changing its options, to stop accepting Cookies or
                to prompt you before accepting a Cookie from the websites you
                visit. If you do not accept Cookies, however, you will not be
                able to stay logged in to the Website. We presently do not
                honour “Do Not Track” requests across all parts of our website.
              </p>

              <p>
                d. Real-Time Location: Certain features of the Application use
                GPS technology to collect real-time information about the
                location of your device so that the Application can connect you
                to a pharmacy professional/healthcare treatment provider near
                your location.
              </p>

              <p>
                e. Device information: We may also collect non-personal
                information from your mobile device or computer. This
                information is generally used to help us deliver the most
                relevant information to you. Examples of information that may be
                collected and used include how you use the application(s) and
                information about the type of device or computer you use. In
                addition, in the event our application(s) crashes on your mobile
                device we will receive information about your mobile device
                model software version and device carrier, which allows us to
                identify and fix bugs and otherwise improve the performance of
                our application(s).
              </p>

              <p>
                e. Device information: We may also collect non-personal
                information from your mobile device or computer. This
                information is generally used to help us deliver the most
                relevant information to you. Examples of information that may be
                collected and used include how you use the application(s) and
                information about the type of device or computer you use. In
                addition, in the event our application(s) crashes on your mobile
                device we will receive information about your mobile device
                model software version and device carrier, which allows us to
                identify and fix bugs and otherwise improve the performance of
                our application(s).
              </p>

              <p>
                7. Without prejudice to clause 5B above, we may disclose your
                personal data where: (i) such disclosure is required for
                performing obligations in the course of or in connection with
                our provision of the goods and services requested by you; or
                (ii) for any of the purposes described in clause 5 above:
              </p>

              <p>
                a. To our subsidiaries, related companies, affiliates, or
                partners;
              </p>

              <p>
                b. To contractors (including medical specialists, Pharmacists,
                Pharmacy Technicians, diagnostic and medical laboratories and
                other healthcare providers), service providers and other third
                parties we use to support our business and who are bound by
                contractual obligations to keep personal information
                confidential and use it only for the purposes for which we
                disclose it to them (including logistics service providers, data
                service providers);
              </p>

              <p>
                c. As required by law, which can include providing information
                as required by a court order;
              </p>

              <p>
                d. When we believe in good faith that disclosure is necessary to
                protect your safety or the safety of others, to protect our
                rights, to investigate fraud, or to respond to a government
                request;
              </p>

              <p>
                e. To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution or other
                sale or transfer of some or all of TPC’s assets, whether as a
                going concern or as part of bankruptcy, liquidation or similar
                proceeding, in which personal data maintained on the Platforms
                is among the assets transferred;
              </p>

              <p>
                f. To any other person or organisation disclosed by us when you
                provide the information.
              </p>

              <p>
                8. The purposes listed in the above clauses may continue to
                apply even in situations where your relationship with us (for
                example, pursuant to your employment contract should you be
                hired) has been terminated or altered in any way, for a
                reasonable period thereafter (including, where applicable, a
                period to enable us to enforce our rights under a contract with
                you).
              </p>

              <p>
                9. This Privacy Policy applies only to information we collect
                through the Platforms and in email, text and other electronic
                communications sent through or in connection with the Platforms.
                This policy does not apply to information collected by any third
                party. When you click on links on the Website, you may leave our
                site. We are not responsible for the privacy practices of other
                sites, and we encourage you to read their privacy statements
              </p>

              <h4> WITHDRAWING YOUR CONSENT</h4>

              <p>
                10. The consent that you provide for the collection, use and
                disclosure of your personal data will remain valid until such
                time it is being withdrawn by you in writing. You may withdraw
                consent and request us to stop collecting, using and/or
                disclosing your personal data for any or all of the purposes
                listed above by submitting your request via email to our Data
                Protection Officer at the contact details provided below.
              </p>

              <p>
                11. Upon receipt of your written request to withdraw your
                consent, we may require reasonable time (depending on the
                complexity of the request and its impact on our relationship
                with you) for your request to be processed and for us to notify
                you of the consequences of us acceding to the same, including
                any legal consequences which may affect your rights and
                liabilities to us. In general, we shall seek to process your
                request within ten (10) business days of receiving it.
              </p>

              <p>
                12. Whilst we respect your decision to withdraw your consent,
                please note that depending on the nature and scope of your
                request, we may not be in a position to continue providing our
                goods or services to you and we shall, in such circumstances,
                notify you before completing the processing of your request.
                Should you decide to cancel your withdrawal of consent, please
                inform us in writing in the manner described in clause 10 above.
              </p>

              <p>
                13. Please note that withdrawing consent does not affect our
                right to continue to collect, use and disclose personal data
                where such collection, use and disclose without consent is
                permitted or required under applicable laws. For the avoidance
                of doubt, such withdrawal of consent shall not require us to
                delete any personal data that has already been collected by us
                from our records. Such retention shall be subject to clauses 20
                to 21 below.
              </p>

              <h4>ACCESS TO AND CORRECTION OF PERSONAL DATA</h4>

              <p>
                14. If you wish to make (a) an access request for access to a
                copy of the personal data which we hold about you or information
                about the ways in which we use or disclose your personal data,
                or (b) a correction request to correct or update any of your
                personal data which we hold about you, you may submit your
                request via email to our Data Protection Officer at the contact
                details provided below. Such request shall include the details
                of the requestor, description of the personal data being
                requested and the date and time range the personal data was
                believed to be collected.
              </p>

              <p>
                15. Please note that a reasonable fee may be charged for an
                access request. If so, we will inform you of the fee before
                processing your request.
              </p>

              <p>
                16. We will respond to your request as soon as reasonably
                possible. In general, our response will be within fourteen (14)
                business days. Should we not be able to respond to your request
                within thirty (30) days after receiving your request, we will
                inform you in writing within thirty (30) days of the time by
                which we will be able to respond to your request. If we are
                unable to provide you with any personal data or to make a
                correction requested by you, we shall generally inform you of
                the reasons why we are unable to do so (except where we are not
                required to do so under the GDPR).
              </p>

              <h4>PROTECTION OF PERSONAL DATA</h4>

              <p>
                17. To safeguard your personal data from unauthorised access,
                collection, use, disclosure, copying, modification, disposal or
                similar risks, we have introduced appropriate administrative,
                physical and technical measures such as up-to-date antivirus
                protection, encryption, and disclosing personal data both
                internally and to our authorised third-party service providers
                and agents only on a need-to-know basis.
              </p>

              <p>
                18. You should be aware, however, that no method of transmission
                over the Internet or method of electronic storage is completely
                secure. While security cannot be guaranteed, we strive to
                protect the security of your information and are constantly
                reviewing and enhancing our information security measures.
              </p>

              <h4>ACCURACY OF PERSONAL DATA</h4>

              <p>
                19. We generally rely on personal data provided by you (or your
                authorised representative). In order to ensure that your
                personal data is current, complete and accurate, please update
                us if there are changes to your personal data by informing our
                Data Protection Officer via email at the contact details
                provided below.
              </p>

              <h4>RETENTION OF PERSONAL DATA</h4>

              <p>
                20. We may retain your personal data for as long as it is
                necessary to fulfil the purpose for which it was collected, or
                as required or permitted by applicable laws.
              </p>

              <p>
                21. We will cease to retain your personal data, or remove the
                means by which the data can be associated with you, as soon as
                it is reasonable to assume that such retention no longer serves
                the purpose for which the personal data was collected, and is no
                longer necessary for legal or business purposes.
              </p>

              <h4>DATA PROTECTION OFFICER</h4>

              <p>
                You may contact our Data Protection Officer if you have any
                enquiries or feedback on our personal data protection policies
                and procedures, or if you wish to make any request, in the
                following manner:
              </p>

              <p>
                Email Address:{" "}
                <Link to="mailto:gdpr@telepharmacare.com">
                  gdpr@telepharmacare.com
                </Link>
              </p>

              <h4>EFFECT OF PRIVACY POLICY AND CHANGES TO PRIVACY POLICY</h4>

              <p>
                This Privacy Policy applies in conjunction with any other
                notices, contractual clauses and consent clauses that apply in
                relation to the collection, use and disclosure of your personal
                data by us.
              </p>

              <p>
                This Privacy Policy applies in conjunction with any other
                notices, contractual clauses and consent clauses that apply in
                relation to the collection, use and disclosure of your personal
                data by us.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
