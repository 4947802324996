import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../Services/ConfigApi";
import { toast } from "react-toastify";

// Contact us Form
export const ContactUs = createAsyncThunk("ContactUs", async (details) => {
  const data = await API.post("/Contactussave", details);
  console.log(data);
  return data;
});

// Blog list

export const getBlogs = createAsyncThunk("getBlogs", async (details) => {
  const { data } = await API.get(`/published_blogs_listing`, details);
  console.log(data);

  return data;
});

// Get Blog BY id

export const findblogbyId = createAsyncThunk(
  "findblogbyId",
  async (details) => {
    const { data } = await API.post(`/find_blogs`, details);
    if (data?.status == "Success") {
      console.log(data);
    } else {
      toast.error(data?.message);
    }

    return data;
  }
);

// add comment

export const addComment = createAsyncThunk("addComment", async (details) => {
  const { data } = await API.post(`/update_blogs_comments`, details);

  return data;
});

// blog comments

export const BlogComments = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/find_blog_comments`, details);

    return data;
  } catch (error) {
    toast.error(error);
  }
};

// landng Page content

export const landingPage = createAsyncThunk("landingPage", async (details) => {
  const { data } = await API.get(`/landing-page-content`, details);

  return data;
});
