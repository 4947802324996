import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
export default function CookiePolicy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <section className="page-mt pt-5">
        <Container>
          <div class="container">
            <div class="privacy_text">
              <div class="header-logo text-center">
                <Link class="main-logo link" to="/">
                  <img src={require("../images/logo-update.svg").default} />
                </Link>
              </div>
              <h3>Cookie Policy</h3>
              <p>Effective Date: 2023-04-23</p>
              <p>
                This Cookie Policy ("Policy") explains how we use cookies and
                similar technologies on 
                <Link to={"https://telepharmacare.com"}> https://telepharmacare.com</Link> OR ANY OTHER
                RELATED WEBSITES (“WEBSITES”), AND TELEPHARMACARE MOBILE
                APPLICATION TOGETHER WITH ITS VARIOUS FUNCTIONALITIES
                ("APPLICATION") (collectively, the “PLATFORMS”). By using our
                Platforms, you consent to the use of cookies as described in
                this Policy.
              </p>
              <h5>1. What Are Cookies?</h5>
              <p>
                Cookies are small text files that are placed on your device
                (e.g., computer, smartphone, or tablet) when you visit a website
                or use an application. They are widely used to make websites and
                applications work more efficiently and to provide information to
                the owners of the site or application.
              </p>
              <h5>2. Types of Cookies We Use</h5>
              <p>
                2.1 Essential Cookies: These cookies are necessary for the
                functioning of our Platforms and enable you to navigate and use
                its features. They are usually set in response to actions you
                take, such as logging in or filling out forms. Without these
                cookies, certain features of the Platforms may not be available
                to you.
              </p>
              <p>
                2.2 Performance and Analytics Cookies: We use these cookies to
                collect information about how visitors use our Platforms, such
                as which pages are visited most often and if users receive error
                messages from web pages. This helps us improve the performance
                and usability of our Platforms and better understand how users
                interact with it.
              </p>
              <p>
                2.3 Functionality Cookies: These cookies allow our Platforms to
                remember choices you make (such as your username, language, or
                region) and provide enhanced and personalized features. They may
                also be used to provide services you have requested, such as
                remembering your preferences or settings.
              </p>
              <p>
                2.4 Advertising Cookies: We may use advertising cookies to
                deliver advertisements that are relevant to your interests.
                These cookies may collect information about your online
                activities, such as the websites you visit and the links you
                click. They help us measure the effectiveness of our advertising
                campaigns and customize the content of the advertisements you
                see.
              </p>
              <h5>3. Third-Party Cookies</h5>
              <p>
                We may allow third-party service providers to place cookies on
                your device through our Platforms. These providers may use
                cookies for various purposes, such as analysing usage patterns,
                providing personalized advertisements, or delivering content
                from social media platforms. We do not have control over these
                third-party cookies, and their use is subject to the third
                party's privacy policies.
              </p>
              <h5>4. Managing Cookies</h5>
              <p>
                Most web browsers allow you to control and manage cookies
                through their settings. You can usually set your browser to
                block cookies, delete existing cookies, or prompt you before
                accepting a new cookie. However, please note that if you disable
                or delete cookies, some features of our Platforms may not
                function properly, and your user experience may be impacted.
              </p>
              <h5>5. Updates to this Policy</h5>
              <p>
                We may update this Policy from time to time to reflect changes
                in our use of cookies or legal requirements. We will notify you
                of any material changes by posting a revised version on our
                Platforms. We encourage you to review this Policy periodically
                to stay informed about our use of cookies.
              </p>
              <h5>6. Contact Us</h5>
              <p>
                If you have any questions or concerns about this Cookie Policy,
                please contact us at:{" "}
                <Link to="mailto:support@telepharmacare.com">
                  support@telepharmacare.com
                </Link>{" "}
              </p>
              <p>
                By using our Platforms, you signify your acceptance of this
                Cookie Policy. If you do not agree to this Policy, please do not
                use our Platforms.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
